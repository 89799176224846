import React from "react"
import Slider from "react-slick"
import { convertToBgImage } from "gbimage-bridge"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Button } from "../components/CompoundComponents/Button"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const StyledInnerWrapper = styled.div`
  display: block;
`

export default function SimpleSlider({ data }) {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    focusOnSelect: false,
    dots: false,
    pauseOnHover: false,
  }
  return (
    <Slider {...settings}>
      {data.edges.map(background => {
        const image = getImage(background.node.featuredImage)
        const bgImage = convertToBgImage(image)
        return (
          <StyledInnerWrapper>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              className={`hero has-text-centered-mobile is-fullheight`}
            >
              <div className="hero-body has-background-hero">
                <div className="container">
                  <Columns vcentered centered>
                    <Column tcentered>
                      <h2 className="is-size-1 is-size-4-mobile has-text-white is-uppercase has-text-weight-light mb-1">
                        Multi Business Company
                      </h2>

                      <Link to="/contact">
                        <Button primary blackTheme>
                          Написать
                        </Button>
                      </Link>
                    </Column>
                  </Columns>
                </div>
              </div>
            </BackgroundImage>
          </StyledInnerWrapper>
        )
      })}
    </Slider>
  )
}
