import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"

const SectionTwo = ({ data }) => {
  return (
    <Section noPaddingBottom>
      <Columns vcentered tmcentered>
        <Column is4 offset1 centered>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <SectionTitle>Качество нового уровня</SectionTitle>
            <SectionSubtitle>
              Гарантируем качественный продукт в кратчайшие сроки
            </SectionSubtitle>
          </div>
        </Column>
        <Column is6 offset1>
          <GatsbyImage
              image={getImage(data.edges[0].node.featuredImage)}
          />
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionTwo
