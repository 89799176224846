import React from "react"
import { Link } from "gatsby"
import Carousel from "react-multi-carousel"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import { Card, CardContent, CardContentTitle } from "../CompoundComponents/Card"
import { Button } from "../CompoundComponents/Button"

import "react-multi-carousel/lib/styles.css"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const SectionFour = ({ data }) => {
  return (
    <Section white medium>
      <Columns vcentered centered tcentered>
        <Column is12>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <SectionTitle>Наша продукция</SectionTitle>
          </div>
          <SectionSubtitle>
            Мы всегда делаем все возможное, чтобы оправдать доверие наших
            потребителей
          </SectionSubtitle>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <Link to="/service">
              <Button bottom primary whiteTheme>
                Продукты и услуги
              </Button>
            </Link>
          </div>
          <Carousel
            responsive={responsive}
            swipeable
            draggable
            autoPlay
            infinite
            ssr={false}
            showDots
          >
            {data.edges.map(item => (
              <Link to={item.node.link}>
                <Card>
                  <CardContent>
                    <CardContentTitle>{item.node.title}</CardContentTitle>
                    <h3 className="is-hidden">
                      <Button primary whiteTheme normal>
                        {item.node.button} <span>{item.node.span}</span>
                      </Button>
                    </h3>
                  </CardContent>
                </Card>
              </Link>
            ))}
          </Carousel>
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionFour
