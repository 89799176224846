import React from "react"
import Sticky from "react-sticky-el"
import { graphql } from "gatsby"
import SEO from "../components/SEO/index"
import SectionOne from "../components/Sections/Section"
import SectionTwo from "../components/Sections/SectionTwo"
import SectionThree from "../components/Sections/SectionThree"
import SectionFour from "../components/Sections/SectionFour"
import SectionFive from "../components/Sections/SectionFive"
import Slider from "../components/Slider"
import NavBar from "../components/navbar"

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="UZMBC - Multi Business Company - Герметики на основе ПВХ Узбекистан, Автомобильные ЛКП Узбекистан, Сухие строительные смеси Узбекистан, Растворитель (Нефрас) Узбекистан, Полиуретановые герметики Узбекистан, Ремонтные шпатлевки Узбекистан, Строительные ЛКП Узбекистан, Дорожные ЛКП Узбекистан, Гидрофобный мел Узбекистан, Обшивка автомобильных сидений Узбекистан, Обшивка для мебелей Узбекистан, Материалы для брезентов Узбекистан, Герметик купить Узбекистан, Герметик цена Узбекистан, Краски купить Узбекистан, Краски цена Узбекистан, Автомобильные краски купить Узбекистан, Строительные смеси Узбекистан"
        description="Наши продукции и услуги: Герметики на основе ПВХ, Автомобильные ЛКП, Сухие строительные смеси, Растворитель (Нефрас), Полиуретановые герметики, Ремонтные шпатлевки, Строительные ЛКП, Дорожные ЛКП, Гидрофобный мел, Обшивка автомобильных сидений, Обшивка для мебелей, Материалы для брезентов, Герметик купить Узбекистан, Герметик цена Узбекистан, Краски купить Узбекистан, Краски цена Узбекистан, Автомобильные краски купить Узбекистан, Строительные смеси Узбекистан"
      />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <Slider data={data.slider} />
      <SectionFour data={data.product} />
      <SectionTwo data={data.section} />
      <SectionOne />
      <SectionThree data={data.section} />
      <SectionFive data={data.section} />
    </>
  )
}

export const IndexPageQuery = graphql`
  query {
    slider: allSliderYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
    product: allProductsYaml {
      edges {
        node {
          title
          button
          span
          link
        }
      }
    }
    section: allSectionYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          } 
        }
      }
    }
  }
`

export default IndexPage
