import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import { CardImage } from "../CompoundComponents/Card";

const SectionThree = ({ data }) => {
  return (
    <Section>
      <Columns vcentered rowReverse tmcentered>
        <Column is5 offset1>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <SectionTitle>Кратчайшие сроки поставки</SectionTitle>
          </div>
        </Column>
        <Column is5>
          <CardImage>
            <GatsbyImage
                image={getImage(data.edges[1].node.featuredImage)}
            />
          </CardImage>
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionThree
