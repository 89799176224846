import React from "react"
import { Link } from "gatsby"
import Carousel from "react-multi-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"
import { Button } from "../CompoundComponents/Button"

import "react-multi-carousel/lib/styles.css"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const SectionFive = ({ data }) => {
  return (
    <Section medium white>
      <Columns vcentered>
        <Column is6 tmcentered centered>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <SectionTitle bottom>Новости и события</SectionTitle>
          </div>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <Link to="news">
              <Button primary whiteTheme>
                Все события
              </Button>
            </Link>
          </div>
        </Column>
        <Column is6>
          <Carousel
            responsive={responsive}
            swipeable
            draggable
            autoPlay
            infinite
            ssr={false}
            showDots
            arrows={false}
          >
            <Box>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon
                    icon="calendar-alt"
                    size="sm"
                    color="#000000"
                  />
                </span>
                <span>01/02/21</span>
              </div>
              <p class="block mb-1">Производство гидрофобного мела.</p>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon icon="star" size="sm" color="#000000" />
                </span>
                <span className="has-text-weight-bold">
                  Открытие производства
                </span>
              </div>
            </Box>
            <Box>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon
                    icon="calendar-alt"
                    size="sm"
                    color="#000000"
                  />
                </span>
                <span>01/03/21</span>
              </div>
              <p class="block mb-1">
                Производство автомобильной лобовой герметики.
              </p>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon icon="star" size="sm" color="#000000" />
                </span>
                <span className="has-text-weight-bold">
                  Открытие производства
                </span>
              </div>
            </Box>
            <Box>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon
                    icon="calendar-alt"
                    size="sm"
                    color="#000000"
                  />
                </span>
                <span>01/06/21</span>
              </div>
              <p class="block mb-1">
                Производство текстильных материалов (обшивки мебелей и
                автомобильных сиденья).
              </p>
              <div className="icon-text">
                <span className="icon radius-50 has-background-primary">
                  <FontAwesomeIcon icon="star" size="sm" color="#000000" />
                </span>
                <span className="has-text-weight-bold">
                  Открытие производства
                </span>
              </div>
            </Box>
          </Carousel>
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionFive
