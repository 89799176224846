import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"
import { Button } from "../CompoundComponents/Button"

const SectionOne = () => {
  return (
    <Section medium white>
      <Columns tcentered>
        <Column is12 centered>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <SectionTitle>Почему выбирают нас</SectionTitle>
          </div>
          <SectionSubtitle>
            Нам доверяют UzAuto Motors, Inish Запад, OMYA, Evonik Industries AG
          </SectionSubtitle>
          <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
            <Link to="/about">
              <Button bottom whiteTheme primary>
                О компании
              </Button>
            </Link>
          </div>
        </Column>
        <Column>
          <Box centered noshadow>
            <div className="hvr-icon-pop">
              <span className="icon is-large radius-50 has-background-light mb-1">
                <FontAwesomeIcon
                  className="hvr-icon"
                  icon="industry"
                  size="2x"
                  color="#008bd2"
                />
              </span>
              <p class="block has-family-pt-sans has-text-weight-bold">
                Местный производитель
              </p>
            </div>
          </Box>
        </Column>
        <Column>
          <Box centered noshadow>
            <div className="hvr-icon-pop">
              <span className="icon is-large radius-50 has-background-light mb-1">
                <FontAwesomeIcon
                  className="hvr-icon"
                  icon="globe-asia"
                  size="2x"
                  color="#008bd2"
                />
              </span>
              <p class="block has-family-pt-sans has-text-weight-bold">
                Лаборатории мирового стандарта
              </p>
            </div>
          </Box>
        </Column>
        <Column>
          <Box centered noshadow>
            <div className="hvr-icon-pop">
              <span className="icon is-large radius-50 has-background-light mb-1">
                <FontAwesomeIcon
                  className="hvr-icon"
                  icon="robot"
                  size="2x"
                  color="#008bd2"
                />
              </span>
              <p class="block has-family-pt-sans has-text-weight-bold">
                Новейшие оборудования
              </p>
            </div>
          </Box>
        </Column>

        <Column>
          <Box centered noshadow>
            <div className="hvr-icon-pop">
              <span className="icon is-large radius-50 has-background-light mb-1">
                <FontAwesomeIcon
                  className="hvr-icon"
                  icon="gem"
                  size="2x"
                  color="#008bd2"
                />
              </span>
              <p class="has-family-pt-sans has-text-weight-bold">
                Качественные материалы
              </p>
            </div>
          </Box>
        </Column>
      </Columns>
    </Section>
  )
}

export default SectionOne
